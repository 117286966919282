
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import useNotify from "@/core/composables/notify";
import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import { useStore } from "vuex";
import { User } from "@/store/models/user";
import { SubscriptionStatus } from "@/assets/ts/_utils/enums/subscription.enum";
import Spinner from "@/components/Spinner.vue";
import { ADMIN } from "@/constants";
import usePayments from "@/core/composables/usePayments";

export default defineComponent({
  name: "billing",
  components: {
    Datatable,
    Spinner,
  },
  setup() {
    const { fetchBilling, loading: loadingBilling } = usePayments();
    const { toastErrorRaw } = useNotify();

    const isAdmin = computed(
      () => store.getters.currentUser.user_type === ADMIN
    );

    // const currency = computed(
    //   () => store.getters.currentUser.user_type === ADMIN
    // );

    const store = useStore();

    const tableHeader = computed(() => {
      const items = [
        {
          name: "Created at",
          key: "created",
        },
        {
          name: "Reference Number",
          key: "reference_number",
        },
        {
          name: "Amount",
          key: "amount",
          sortable: true,
        },
        // {
        //   name: "Plan",
        //   key: "plan",
        // },
        // {
        //   name: "Created Date",
        //   key: "created",
        // },
        // {
        //   name: "Usage",
        //   key: "use",
        // },
      ];

      // if (!isAdmin.value) {
      //   items.push({
      //     name: "Actions",
      //     key: "edit",
      //   });
      // }
      return items;
    });

    const tableData = ref<Array<IDVerification>>([]);
    const tableKey = ref(1);
    const selectedRow = ref<User | null>(null);
    const updating = ref(null);

    const fetchData = async (search?: string) => {
      try {
        tableData.value = [];

        const params: any = {};
        if (search) {
          params.search = search;
        }

        // const resp = await fetchSubscriptions();
        const resp = await fetchBilling();
        console.log("🚀 ~ fetchData ~ respo:", resp);
        tableData.value = resp.results;
        setCurrentPageBreadcrumbs("Billing", ["Billing List"]);
        tableKey.value = new Date().getTime();
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    // const toggleAutoRenew = async (subscription) => {
    //   if (updating.value == subscription.id) {
    //     return toastErrorRaw(new Error("Currently updating subscription"));
    //   }

    //   try {
    //     updating.value = subscription.id;
    //     await ApiService.patch(`/subscriptions/${subscription.id}/`, {
    //       autorenew: !subscription.autorenew,
    //     } as any);
    //     await fetchData();
    //     toastSuccess({ text: "Autorenewal toggled successfully" });
    //   } catch (error) {
    //     toastErrorRaw(error);
    //   } finally {
    //     updating.value = null;
    //   }
    // };

    // const renew = async (subscription) => {
    //   if (updating.value == subscription.id) {
    //     return toastErrorRaw(new Error("Currently renewing subscription"));
    //   }

    //   try {
    //     updating.value = subscription.id;
    //     await ApiService.post(`/subscriptions/${subscription.id}/renew/`, {});
    //     await fetchData();
    //     toastSuccess({ text: "Subscription renewed successfully" });
    //   } catch (error) {
    //     toastErrorRaw(error);
    //   } finally {
    //     updating.value = null;
    //   }
    // };

    onMounted(async () => {
      await fetchData();
    });

    return {
      tableData,
      tableHeader,
      selectedRow,
      tableKey,
      // toggleAutoRenew,
      authUser: store.getters.currentUser,
      SubscriptionStatus,
      updating,
      // renew,
      // loadingSubscriptions,
      loadingBilling,
      isAdmin,
    };
  },
});
